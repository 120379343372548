import React from "react";
import "./index.css";

import logo from "../images/logobrancovertuno.svg"
import barravertical from "../images/barravertical.svg"
import facebook from "../images/facebranco.svg"
import instagram from "../images/instagrambranco.svg"
import linkedin from "../images/linkedinbranco.svg"

import parte1_logo from "../images/parte1_logo.svg"
import parte2_logo from "../images/parte2_logo.svg"
import parte3_logo from "../images/parte3_logo.svg"

import Habits1 from "../images/habits1.png"
import Habits2 from "../images/habits2.png"
import Agua from "../images/water_historico.png"
import Plataforma from "../images/plataformagpp_historico.png"
import Associacao from "../images/assosicaoguarauna_historico.png"
import AgendaPublica1 from "../images/agendapublica1.historico.png"
import AgendaPublica2 from "../images/agendapublica2.historico.png"
import FEAjr from "../images/FEAjr_historico.jpg"
import Secretaria from "../images/secretariaeducao_historico.png"
import Cooperacao from "../images/cooperacaointernacional_historico.png"

import logomarcas from "../images/logomarcas.png"

import seta from "../images/flechabranca.svg"

import Contato from '../pag/sec_contato/contato';

import {useRef} from 'react';

const Pagina = () => {
    window.addEventListener('scroll', () => {
        const headerelement = document.querySelector('.header');
        if (window.scrollY > 140) {
            if (!(headerelement.classList.contains("header-scrolled_header"))) {
                headerelement?.classList.add('header-scrolled_header');
            }
        }
        else if (window.scrollY <= 140) {
            if (headerelement.classList.contains("header-scrolled_header")) {
                headerelement?.classList.remove('header-scrolled_header');
            }
        }
    });

    const getPercentageByScroll = () => {
        const alturaWindow = window.innerHeight;
        const alturaTotal = document.documentElement.scrollHeight;
        const scroll = window.scrollY;
        const porcentagemscroll = (scroll / (alturaTotal - alturaWindow)) * 100;
        return porcentagemscroll;
    }

    const fillProgressBar = () => {
        const barraprogresso = document.querySelector('.scroll-line_header');
        const percentagebyscroll = getPercentageByScroll();
        barraprogresso.style.width = percentagebyscroll + '%';
    }

    const handleScroll = () => {
        fillProgressBar();
    }

    window.addEventListener('scroll', handleScroll);

    const capa = useRef(null);

    const moveCapa = () => {
      capa.current?.scrollIntoView({behavior: 'smooth'});
    };

    const sobrenos = useRef(null);

    const moveSobreNos = () => {
      sobrenos.current?.scrollIntoView({behavior: 'smooth'});
    };

    const solucoes = useRef(null);

    const moveSolucoes = () => {
      solucoes.current?.scrollIntoView({behavior: 'smooth'});
    };

    const contatos = useRef(null);

    const moveContatos = () => {
      contatos.current?.scrollIntoView({behavior: 'smooth'});
    };


    return (
        <div>
            <head>
                <link href='https://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'/>
                <link href='https://fonts.googleapis.com/css?family=Inter:400,700' rel='stylesheet' type='text/css'/>
            </head>

            <section id="header">

                <div className="scroll-line_header"></div>
                <div className="progress-bar_header">
                    <div className="filled_header"></div>
                </div>
                <img className="logo_header" src={logo} alt="imagem_logo" onClick={moveCapa}></img>
                <div className="header">
                    <a className="nome_header" onClick={moveCapa}>VERTUNO</a>
                    <nav>
                        <ul className="links_header">
                            <li id="link1_header" className="link-item_header">
                                <a className="link_header" onClick={moveSobreNos}>Sobre Nós</a>
                            </li>
                            <li><a className="link_header" onClick={moveSolucoes}>Soluções</a></li>
                            <li><a className="link_header" onClick={moveContatos}>Contato</a></li>
                        </ul>
                    </nav>
                    <img className="barravertical_header" src={barravertical}></img>
                    <div className="socialmedia_header">
                        <a className="float_header instagram_header" href="https://instagram.com/vertuno.usp?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                            <img className="float_header instagram_header" src={instagram} alt="icone_instagram"></img>
                        </a>
                        <a className="float_header linkedin_header" href="https://www.linkedin.com/company/vertuno/" target="_blank" rel="noopener noreferrer">
                            <img className="float_header linkedin_header" src={linkedin} alt="icone_linkedin"></img>
                        </a>
                        <a className="float_header facebook_header" href="https://www.facebook.com/VertunoGPP?mibextid=b06tZ0" target="_blank" rel="noopener noreferrer">
                            <img className="float_header facebook_header" src={facebook} alt="icone_facebook"></img>
                        </a>
                    </div>
                </div>
            </section>

            <section id="capa" ref={capa}>
                <div className="planodefundo_capa">
                    <h1 className="titulo_capa">VERTUNO</h1>
                    <div className="servico_capa">Desenvolvemos serviços especializados e focados em gestão, planejamento, capacitação e políticas públicas.</div>
                    <div className="buttons_capa">
                        <a href="#contatos" className="button_capa" id="primeiro_capa"><p>Agende um diagnóstico</p></a>
                        <a className="button_capa" id="segundo_capa" onClick={moveSolucoes}><p>Conheça nossas soluções</p></a>
                    </div>
                </div>
            </section>

            <section id="sobre_nos" ref={sobrenos}>
                <div className="backsobrenos_sobrenos">
                    <div className="backgroundtransparency_sobrenos" />
                    <div className="sobrenos">
                        <img className="logo1_sobrenos" src={parte1_logo}></img>
                        <img className="logo2_sobrenos" src={parte2_logo}></img>
                        <img className="logo3_sobrenos" src={parte3_logo}></img>
                        <h1 className="TituloSobreNos_sobrenos">Sobre Nós</h1>
                        <div className="texto_sobrenos_sobrenos">
                            <p id="p1_sobrenos" className="descricaosobrenos_sobrenos">

                                A Vertuno, Empresa Júnior de Gestão de Políticas Públicas da Escola de Artes, Ciências e Humanidades da Universidade de São Paulo, surge no ano de 2016 a partir do anseio de quatro alunos de contribuir para impactar a sociedade por meio de projetos de excelência na Gestão Pública.</p>
                            <br></br>
                            <br></br>

                            <p id="p2_sobrenos" className="descricaosobrenos_sobrenos">
                                Assim, unindo essa necessidade e o apoio técnico de professores do curso, a Empresa atua no aprimoramento da máquina pública e na geração de valor para sociedade através de projetos de prateleira ou  desenhados sob demanda.</p>

                        </div>
                    </div>
                </div>
            </section>

            <section id="infovertuno">
                <div className="fundoAquinaV_info">
                    <h1>Aqui na Vertuno...</h1>
                    <div className="retangulobranco_info">
                        <div id="num1_info" className="numeroinfo_info">+140</div>
                        <div id="num2_info" className="numeroinfo_info">+35</div>
                        <div id="num3_info" className="numeroinfo_info">7</div>
                        <div id="descr1_info" className="descr_info">membros já passaram por aqui</div>
                        <div id="descr2_info" className="descr_info">projetos realizados com sucesso</div>
                        <div id="descr3_info" className="descr_info">anos de empresa</div>
                    </div>
                </div>
            </section>

            <section id="historico">
                <div className="fundohistorico_historico">
                    <div id="v1_historico" className="verticalrectg_historico"></div>
                    <div id="c1_historico" className="circulobranco_historico"></div>

                    <div id="v2_historico" className="verticalrectg_historico"></div>
                    <div id="c2_historico" className="circulobranco_historico"></div>

                    <div id="v3_historico" className="verticalrectg_historico"></div>
                    <div id="c3_historico" className="circulobranco_historico"></div>

                    <div id="v4_historico" className="verticalrectg_historico"></div>
                    <div id="c4_historico" className="circulobranco_historico"></div>

                    <div id="v5_historico" className="verticalrectg_historico"></div>
                    <div id="c5_historico" className="circulobranco_historico"></div>

                    <div id="v6_historico" className="verticalrectg_historico"></div>
                    <div id="c6_historico" className="circulobranco_historico"></div>

                    <div id="v7_historico" className="verticalrectg_historico"></div>
                    <div id="c7_historico" className="circulobranco_historico"></div>

                    <div id="v8_historico" className="verticalrectg_historico"></div>
                    <div id="c8_historico" className="circulobranco_historico"></div>

                    <div id="v9_historico" className="verticalrectg_historico"></div>
                    <div id="c9_historico" className="circulobranco_historico"></div>

                    <div id="v10_historico" className="verticalrectg_historico"></div>


                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div id="ano1_historico" className="ano_historico">2018</div>
                            <div className="subtitulo_historico">Instituto Jus</div>
                        </div>
                        <div className="secaohistorico_historico">

                            <p id="descr1_historico" className="descricao_historico">Projeto em conjunto: Oferecimento da disciplina Unicidades (matéria Intersemestral
                                Planejamento Urbano), pensando em um planejamento estratégico para o
                                desenvolvimento regional de pequenos municípios do estado de São Paulo. </p>
                            <br />
                            <div className="retangulofino_historico"></div>
                        </div>
                    </div>

                    <div className="intervalo_etapas_historico"></div>


                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2019</div>
                            <div className="subtitulo_historico">Habits</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <p className="descricao_historico">Realizamos uma série de workshops, a fim de capacitar a instituição a entender
                                mais sobre o setor público e sua administração, como entender as possibilidades de gestão, como fazer parcerias e contratos; além disso, foram realizados workshops sobre as possíveis parcerias entre o primeiro e o terceiro setor. </p>
                            <br />
                            <div className="retangulofino_historico"></div>
                            <div className="fotos-container_historico">
                                <img id="habt2_historico" src={Habits2}></img>
                                <div id="coluna_vertical_historico"></div>
                                <img id="habt1_historico" src={Habits1}></img>
                            </div>
                            <p className="descricao_historico">

                                Alguns dos temas dos nossos workshops foram:
                                <ul>
                                    <li> Parcerias Estado e Terceiro Setor: Formas de Contratualização com o Setor Público Brasileiro;</li>
                                    <li>Parcerias Estado e Terceiro Setor na Gestão Social.</li>
                                </ul>
                                <br />
                            </p>
                            <div className="retangulofino_historico"></div>
                        </div>
                    </div>

                    <div className="intervalo_etapas_historico"></div>

                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2020</div>
                            <div className="subtitulo_historico">Marina Helou</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <p className="descricao_historico">Projeto realizado por meio do edital de emenda parlamentar da deputada, sobre
                                sustentabilidade. </p>
                            <br />

                            <img className="fotos_secao_historico" src={Agua}></img>
                            <br />
                            <p className="descricao_historico">
                                Observamos a captação e reutilização de água da chuva e fizemos
                                a instalação de um sistema de captação e filtragem de água da chuva na Ocupação Anchieta em Grajaú
                            </p>
                            <br />
                        </div>
                    </div>

                    <div className="intervalo_etapas_historico"></div>

                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2021</div>
                            <div className="subtitulo_historico">Plataforma Gppista</div>
                        </div>
                        <div className="secaohistorico_historico">

                            <p className="descricao_historico"> Foi inaugurada uma plataforma com o objetivo de:
                                <ul>
                                    <li>
                                        Estruturação de um canal para divulgação de vagas
                                    </li>

                                    <li> Disseminar vagas entre todos e potencializar a relação com egressos</li>

                                    <li>Promover a democratização das vagas
                                    </li>
                                </ul>
                            </p>
                            <img className="fotos_secao_historico" src={Plataforma}></img>
                            <br />
                        </div>
                    </div>
                    <div className="intervalo_etapas_historico"></div>
                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2021</div>
                            <div className="subtitulo_historico">Assosiação Guaraúna</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <p className="descricao_historico">
                                Projeto realizado com o intuito de fazer capacitações a Associação, e assim
                                melhorar o seu funcionamento. <br />
                                As capacitações giraram em torno dos temas:
                                gestão de pessoas, identidade visual e ferramentas de marketing </p>
                            <br />
                            <a href="https://www.instagram.com/p/CI1l4u1hwHC/?igshid=YjNmNGQ3MDY=">
                                <img className="fotos_secao_historico" src={Associacao}
                                ></img>
                            </a>
                            <br />
                            <br />
                            <div className="retangulofino_historico"></div>
                        </div>

                    </div>
                    <div className="intervalo_etapas_historico"></div>
                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2021</div>
                            <div className="subtitulo_historico">Secretaria de Desenvolvimento Econômico do Estado de São Paulista</div>
                            <br />
                            <div id="subtit_hist_2_historico" className="subtitulo_historico_historico">Parceria com FEA Jr</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <p className="descricao_historico">
                                Realização de uma pesquisa qualitativa em profundidade para o entendimento do contexto atual na pandemia de Covid-19;<br />
                                Avaliação da comunicação de políticas públicas voltadas ao setor em questão;<br />
                                Sugestão de melhorias na comunicação da SDE com os micro e pequenos comerciantes.<br /></p>
                            <br />
                            <img className="fotos_secao_historico" src={FEAjr}></img>
                            <br />
                            <br />
                            <div className="retangulofino_historico"></div>
                        </div>
                    </div>
                    <div className="intervalo_etapas_historico"></div>
                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2021</div>
                            <div className="subtitulo_historico">Agenda Pública</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <p className="descricao_historico">
                                O projeto realizado tinha o objetivo de entender como a retomada econômica
                                pós-pandemia estava acontecendo. Para isso, realizamos uma pesquisa em
                                diversos municípios do estado de São Paulo, como Ilha Grande (SP), Arujá (SP),
                                Caieiras (SP), Barbalha (CE) e Ipojuca (PE), a fim de analisar a economia pré e pós
                                pandemia, entendendo se a retomada estava ou não sendo inclusiva.</p>
                            <br />
                            <div className="fotos_agenda_historico">
                                <img id='imgagenda1_historico' src={AgendaPublica1}></img>
                                <img id='imgagenda2_historico' src={AgendaPublica2}></img>
                            </div>
                        </div>
                    </div>
                    <div className="intervalo_etapas_historico"></div>
                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2021</div>
                            <div className="subtitulo_historico">Secretaria Municipal de Educação de Ferraz Vasconcelos</div>
                        </div>
                        <div className="secaohistorico_historico">
                            <div className="descricao_historico">
                                Projeto de mapeamento e redesenho de macroprocesso junto da capacitação dos funcionários, adoção de software de gestão, atualização de ferramentas e reformulação da organização e comunicação interna</div>
                            <br />
                            <img className="fotos_secao_historico" src={Secretaria}></img>
                            <br />
                            <div className="retangulofino_historico"></div>
                        </div>
                    </div>
                    <div className="intervalo_etapas_historico"></div>
                    <div className="etapa_historico">
                        <div className="ano_e_subtitulo_historico">
                            <div className="ano_historico">2022</div>
                            <div className="subtitulo_historico">Cooperação Internacional para o Desenvolvimento Local </div>
                        </div>
                        <div className="secaohistorico_historico">
                            <div className="descricao_historico">
                                Nessa discussão montada com parceria do Centro Acadêmico Herbert de Souza, a Vertuno mediou a mesa para pautar blocos econômicos, orçamento público e relações internacionais no âmbito municipal.
                            </div>
                            <br />
                            <a href="https://www.instagram.com/p/Ci3AL4Au6Ik/?igshid=YjNmNGQ3MDY=">
                                <img className="fotos_secao_historico" src={Cooperacao}></img>
                            </a>
                            <br />
                            <div className="retangulofino_historico"></div>
                        </div>
                    </div>
                    <div className="intervalo_etapas_historico"></div>

                </div>
            </section>

            <section className="container-parceiros">
                    <div className="parcerias_parceiros">
                        <h3 id="parcerias-h3_parceiros">Conheça nossas parcerias</h3>
                        <div className="logos-container_parceiros">
                            <img src={logomarcas} alt="Logomarcas dos parceiros da Vertuno" />
                        </div>
                    </div>
            </section>

            <section id="solucoes" ref={solucoes}>
                <div className="div_soluc">
                    <div className="intro_soluc">
                        < h1>As soluções mais adequadas às suas necessidades.</h1>
                    </div>
                    <div id="sol1_soluc" className="solucao_soluc">
                        <p>Pesquisas quantitativas e qualitativas com enfoque no ciclo de políticas públicas
                        </p>
                    </div>
                    <div id="sol2_soluc" className="solucao_soluc">
                        <p>Mapeamento e redesenho de macroprocessos</p>
                    </div>
                    <div id="sol3_soluc" className="solucao_soluc"><p>Capacitações</p></div>
                    <div id="sol4_soluc" className="solucao_soluc"><p>Projetos personalizados</p></div>
                </div>
            </section>

            <section id="contatos" ref={contatos}>
                <Contato/>
            </section>

            <section id="footer">
                <div className="footer-container_footer">
                    <div className="footer-sec1_footer">
                        <img src={logo}></img>
                        <div className="footer-sec1-titulo_footer">VERTUNO</div>
                        <div className="footer-sec1-subtitulo_footer">
                            {/* <p align="left">
                                Desenvolvemos serviços especializados focados em
                                gestão, planejamento, capacitação e políticas públicas.
                                </p> */}
                        </div>
                    </div>

                    <div className="footer-sec3_footer">
                        <div className="footer-sec3-title_footer"> Redes sociais </div>

                        <div className="footer-sec3-subtitle_footer"><a href="https://instagram.com/vertuno.usp?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer"> Instagram </a></div>
                        <div className="footer-sec3-subtitle_footer"><a href="https://www.linkedin.com/company/vertuno/" target="_blank" rel="noopener noreferrer"> Linkedin </a></div>
                        <div className="footer-sec3-subtitle_footer"><a href="https://www.facebook.com/VertunoGPP?mibextid=b06tZ0" target="_blank" rel="noopener noreferrer"> Facebook </a></div>



                    </div>

                    <div className="footer-sec4_footer">
                        <img src={seta} id="seta"></img>
                        <div onClick={moveCapa}>voltar ao topo</div>
                        <div>©2024 Vertuno, todos os direitos reservados</div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Pagina;